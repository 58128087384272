import React from "react";
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box } from "@mui/system";

const PhotoPreviewer = (props) => {

  const getPreviewCols = () => {
    if (window.innerWidth > 760) {
      return props.images.length;
    }
    return 3;
  }

  const getImage = (file) => {
    const splits = file.name.split(".");
    const extenstion = splits[splits.length - 1];
    let url = props.loaderImage;
    if (!props.allowedVideoTypes.includes(extenstion)) {
      url = URL.createObjectURL(file);
    }
    return <img
      src={url}
      alt={file.name}
      loading="eager"
      style={{minHeight: "50px"}}
    />;
  }

  return (
    <div>
      <Box sx={{ overflowY: "scroll" }}>
        <ImageList
          cols={getPreviewCols()}
          rowHeight={200}
          rowWidth={150}
          variant="quilted"
          gap={2}
          scrollDirection="horizontal"
          justifyContent="center"
          itemLayout="horizontal"
        >
          {props.images.map((image) => (
            <ImageListItem key={image.name} style={{maxWidth: "500px"}}>
              {getImage(image)}
              <ImageListItemBar
                position="top"
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    onClick={() => {
                      props.handleRemoveImage(image)
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </div>
  );
};

export default PhotoPreviewer;
