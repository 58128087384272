import React, { useEffect, useRef } from 'react';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const FullscreenImage = ({ src, alt, onClose }) => {
  const boxRef = useRef(null);

  const handleFullscreen = async (enter) => {
    const elem = boxRef.current;
    try {
      if (enter) {
        if (elem.requestFullscreen) {
          await elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { /* Firefox */
          await elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
          await elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE/Edge */
          await elem.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          await document.exitFullscreen();
        } else if (document.mozCancelFullScreen) { /* Firefox */
          await document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) { /* Chrome, Safari & Opera */
          await document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE/Edge */
          await document.msExitFullscreen();
        }
      }
    } catch (error) {
      console.error(`Fullscreen ${enter ? 'enter' : 'exit'} failed:`, error);
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        onClose();
      }
    };

    // Request fullscreen on mount
    handleFullscreen(true);

    // Add fullscreen change event listener
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      // Exit fullscreen on unmount
      handleFullscreen(false);

      // Remove fullscreen change event listener
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, []);

  return (
    <Box
      ref={boxRef}
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 1)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
      }}
    >
      <IconButton
        sx={{
          position: 'absolute',
          top: 16,
          right: 16,
          color: 'white',
        }}
        onClick={() => {
          handleFullscreen(false);
          onClose();
        }}
      >
        <CloseIcon />
      </IconButton>
      <img
        src={src}
        alt={alt}
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
          width: 'auto',
          height: 'auto',
        }}
      />
    </Box>
  );
};

export default FullscreenImage;
