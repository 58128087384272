import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Tooltip,
} from "@mui/material";
import { createRef, useEffect, useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PlayArrow } from "@mui/icons-material";
import PhotoPreviewer from "./PhotoUploader";
import InputFiles from "react-input-files";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteImage,
  init,
  uploadImagesToBucket,
} from "../../Store/actions/photoActions";
import { StoreConstants } from "../../Store/storeConstants";
import CustomAccordionSummary from "./CustomAccordianSummary";
import { useMediaQuery } from "@mui/material";
import ReactThrobber from "react-throbber";
import { setDefaultTimedError } from "../../Store/actions/errorActions";
import ReactImageVideoLightbox from "react-image-video-lightbox"
import SlideshowDialog from "./SlideshowDialog";
import PhotoSlideshow from "../../Pages/Admin/PhotoSlideshow";

const allowedVideoTypes = ["mp4"];

export const PhotoFrame = () => {
  const dispatch = useDispatch();
  const inputRef = createRef();
  const md = useMediaQuery("(min-width: 960px)");

  const { imagesToUpload, images, isLoading, loaderImg } = useSelector((state) => state.photoReducer);
  const { session } = useSelector((state) => state.userReducer);

  const [showImages, setShowImages] = useState(false);
  const [showSlideshow, setShowSlideshow] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [photoInterval, setPhotoInterval] = useState(5);
  const [refreshInterval, setRefreshInterval] = useState(60);

  useEffect(() => {
    // Function to fetch data from the API
    async function fetchData() {
      dispatch(init());
    }
    // Call the fetchData function when the component mounts
    fetchData();
  }, [dispatch]);

  const downloadImage = (item) => {
    const anchor = document.createElement("a");
    anchor.href = item.url;
    anchor.download = item.key.split("/"[1]);
    anchor.target = "_blank";
    anchor.rel = "noopener noreferrer";
    document.body.appendChild(anchor);
    anchor.click();
  };

  const confirmUpload = () => {
    dispatch(uploadImagesToBucket());
  };

  const handleUpload = (files) => {
    const filteredArray = Array.from(files).filter((file) => true);
    if (files.length > filteredArray) {
      dispatch(setDefaultTimedError("Files with type '.jpg' are not allowed. Please rename them to '.jpeg' first"))
    }
    dispatch({
      type: StoreConstants.UPDATE_UPLOAD_FILES,
      payload: filteredArray,
    });
  };

  const handleRemoveImage = (image) => {
    dispatch({
      type: StoreConstants.UPDATE_UPLOAD_FILES,
      payload: Array.from(imagesToUpload).filter((file) => file !== image),
    });
  };

  const handleAdminDelete = (image) => {
    dispatch(deleteImage(image));
  };

  const openImage = (newImage) => {
    setSelectedImage(newImage);
    setShowImages(true);
    dispatch({
      type: "HIDE_POPUPS"
    });
  }

  const handleStartSlideshow = () => {
    setShowDialog(true);
  }

  const handleSubmitDialog = (photoInterval, refreshInterval) => {
    setShowDialog(false);
    setShowImages(false);
    setPhotoInterval(photoInterval);
    setRefreshInterval(refreshInterval);
    setShowSlideshow(true)
  }

  const handleCloseDialog = () => {
    setShowDialog(false);
  }

  const handleCloseSlideShow = () => {
    setShowSlideshow(false);
  }

  const getFileUrlOrThumbnail = (file) => {
    return file.thumbnail ?? file.url;
  }

  const getData = () => {
    return [...images].map((img) => {
      return {
        url: img.url,
        type: "photo"
      }
    })
  }
  return (
    <Grid container style={{ textAlign: "center" }}>
      <SlideshowDialog
        showDialog={showDialog}
        handleSubmitDialog={handleSubmitDialog}
        handleCloseDialog={handleCloseDialog}
      />
      {
        showSlideshow ? <PhotoSlideshow
          photoInterval={photoInterval}
          refreshInterval={refreshInterval}
          onClose={handleCloseSlideShow}
          allowedVideoTypes={allowedVideoTypes}
        />
          :
          showImages ? <ReactImageVideoLightbox
            data={getData()}
            startIndex={selectedImage}
            showResourceCount={true}
            onCloseCallback={() => {
              setShowImages(false);
              dispatch({
                type: "SHOW_POPUPS"
              });
            }}
            onNavigationCallback={(currentIndex) => {
              setSelectedImage(currentIndex);
            }}
            on
          >
          </ReactImageVideoLightbox> :
            <><Grid item xs={12}>
              {isLoading ? <ReactThrobber
                color="#FF7F50"
                padding="10px"
                size="100px"
              /> :
                <Grid container>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10}>
                    <Accordion
                      expanded={true}
                      sx={{
                        backgroundColor: "rgba(255, 255, 255, 0)",
                      }}
                    >
                      <CustomAccordionSummary
                        onUpload={() => {
                          inputRef.current.click();
                        }}
                        onConfirmUpload={confirmUpload}
                        handleRemoveImage={handleRemoveImage}
                      >
                        <InputFiles onChange={handleUpload} multiple>
                          <button hidden ref={inputRef} />
                        </InputFiles>
                      </CustomAccordionSummary>
                      <AccordionDetails>
                        <PhotoPreviewer
                          images={imagesToUpload}
                          loaderImage={loaderImg}
                          handleRemoveImage={handleRemoveImage}
                          allowedVideoTypes={allowedVideoTypes}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>}
            </Grid>
              <Grid item xs={1}></Grid>
              <Grid
                item
                xs={10}
                justifyContent="center"
                style={{ textAlign: "center" }}
              >
                <Accordion
                  expanded={true}
                  sx={{
                    backgroundColor: "rgba(255, 255, 255, 0)",
                  }}
                >
                  <AccordionSummary style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                    expandIcon={<ExpandMoreIcon />}>
                    <h2>View Pictures</h2>
                    <Box sx={{ width: "80%", textAlign: 'right', m: 1 }}>
                      {session.isAdmin &&
                        <Tooltip title="Start Slideshow">
                          <IconButton onClick={handleStartSlideshow}>
                            <PlayArrow />
                          </IconButton>
                        </Tooltip>
                      }
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box>
                      {md ? (
                        <ImageList variant="masonry" cols={3} gap={8}>
                          {images.map((file, index) => (
                            <ImageListItem key={file.key}>
                              <img src={getFileUrlOrThumbnail(file)} alt={`Busy processing ${file.key}...`} loading="eager" onClick={() => openImage(index)} />
                              <ImageListItemBar
                                position="top"
                                actionIcon={
                                  <>
                                    <IconButton
                                      sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                                      onClick={() => downloadImage(file)}
                                    >
                                      <DownloadIcon />
                                    </IconButton>
                                    {session.isAdmin && (
                                      <IconButton
                                        sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                                        onClick={() => handleAdminDelete(file.key)}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    )}
                                  </>
                                }
                              />
                            </ImageListItem>
                          ))}
                        </ImageList>
                      ) : (
                        <ImageList variant="list" cols={1} gap={8}>
                          {images.map((file) => (
                            <ImageListItem key={file.key}>
                              <img src={getFileUrlOrThumbnail(file)} alt={`Busy processing ${file.key}...`} loading="eager" />

                              <ImageListItemBar
                                position="top"
                                actionIcon={
                                  <>
                                    <IconButton
                                      sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                                      onClick={() => downloadImage(file)}
                                    >
                                      <DownloadIcon />
                                    </IconButton>
                                    {session.isAdmin && (
                                      <IconButton
                                        sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                                        onClick={() => handleAdminDelete(file.key)}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    )}
                                  </>
                                }
                              />
                            </ImageListItem>
                          ))}
                        </ImageList>
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={1}></Grid>
            </>}
    </Grid>
  );
};
