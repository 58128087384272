import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./Store/store";
import { AuthProvider } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";
import awsConfig from "./config/awsConfig";

const cognitoAuthConfig = {
  authority: awsConfig.oauth_authority,
  client_id: awsConfig.aws_user_pools_web_client_id,
  redirect_uri: awsConfig.oauth_redirect_url, // Use the conditionally set redirectUri variable
  response_type: "code",
  scope: "aws.cognito.signin.user.admin email openid phone profile",
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

const root = ReactDOM.createRoot(document.getElementById('root'));
window.store = store;

root.render(
  <React.StrictMode>
    <AuthProvider {...cognitoAuthConfig}>
    <Provider store={store}>
      <App/>
    </Provider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
