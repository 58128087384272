import { StoreConstants } from "../storeConstants";
import awsConfig from "../../config/awsConfig";
import axios from "axios";
import { getHeaders } from "../general";
import { setDefaultTimedError } from "./errorActions";

const photoEndpoint = `${awsConfig.api_endpoint}/photos`;

export const init = () => {
  return (dispatch) => {
    dispatch({ type: "PHOTO_INIT" });
    dispatch(getUploadedImageUrls());
  };
};

export const uploadImagesToBucket = () => {
  return async (dispatch, getState) => {
    const { imagesToUpload } = getState().photoReducer;

    try {
      for (const image of imagesToUpload) {
        const response1 = await axios.put(
          photoEndpoint,
          { name: image.name, type: image.type },
          {
            headers: {
              ...getHeaders(getState),
            },
          }
        );

        const uploadUrl = response1.data;

        await axios.put(uploadUrl, image, {
          headers: {
            "Content-Type": image.type,
          },
        });

        dispatch({
          type: StoreConstants.PUT_PHOTO_SUCCESS,
        });
        dispatch(getUploadedImageUrls());
      }
    } catch (error) {
      dispatch(setDefaultTimedError(error))
    }
  };
};

export const getUploadedImageUrls = () => {
  return (dispatch, getState) => {
    axios
      .get(`${photoEndpoint}`, {
        headers: getHeaders(getState),
      })
      .then((response) => {
        dispatch({
          type: StoreConstants.GET_PHOTOS_SUCCESS,
          payload: { images: response.data.files, loaderImg:response.data.loaderImg, offset: 0 },
        });
      })
      .catch((error) => {
        dispatch(setDefaultTimedError(error))
        dispatch({ type: StoreConstants.CLEAR_LOADING })
      });
  };
};

export const getUploadedImageUrlsSilently = () => {
  return (dispatch, getState) => {
    axios
      .get(`${photoEndpoint}`, {
        headers: getHeaders(getState),
      })
      .then((response) => {
        const { images } = getState().photoReducer;
        const offset = response.data.files.length - images.length;
        dispatch({
          type: StoreConstants.GET_PHOTOS_SUCCESS,
          payload: {
            images: response.data.files,
            loaderImg:response.data.loaderImg,
            offset: offset
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export const deleteImage = (image) => {
  return (dispatch, getState) => {
    axios
      .delete(photoEndpoint, {
        data: image,
        headers: {
          ...getHeaders(getState),
          "Image-key": image,
        },
      })
      .then((url) => {
        dispatch({
          type: StoreConstants.DELETE_PHOTO_SUCCESS,
          payload: url
        });
        dispatch(getUploadedImageUrls());
      })
      .catch((error) => {
        dispatch(setDefaultTimedError(error))
      });
  };
};
