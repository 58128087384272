import { Cancel, Done } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, Input, Slider } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";

const SlideshowDialog = ({ showDialog, handleCloseDialog, handleSubmitDialog }) => {

    const [photoInterval, setPhotoInterval] = useState(5);
    const [refreshInterval, setRefreshInterval] = useState(60);

    return (
        <Dialog open={showDialog} onClose={handleCloseDialog}>
            <DialogTitle>Setup Slideshow</DialogTitle>
            <DialogContent>
                <br/>
                <br/>
                <br/>
                <FormControl>
                    <Grid container style={{ width: "600px" }}>
                        <Grid item xs={12}>Photo Interval</Grid>
                        <Grid item xs={11}>
                            <Slider
                                value={photoInterval}
                                onChange={(e) => setPhotoInterval(e.target.value)}
                                aria-labelledby="slider-with-input"
                                valueLabelDisplay="on"
                                color="secondary"
                                min={1}
                                max={600}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Input
                                value={photoInterval}
                                size="small"
                                onChange={(e) => setPhotoInterval(e.target.value ?? 0)}
                                inputProps={{
                                    step: 5,
                                    min: 1,
                                    max: 600,
                                    type: 'number',
                                    'aria-labelledby': 'slider-with-input',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>Refresh Interval</Grid>
                        <Grid item xs={11}>
                            <Slider
                                value={refreshInterval}
                                onChange={(e) => setRefreshInterval(e.target.value)}
                                aria-labelledby="slider-with-input"
                                valueLabelDisplay="on"
                                color="secondary"
                                min={1}
                                max={600}
                                marks={[1, 5, 10, 15, 20, 30, 45, 60, 90, 120, 240, 300, 360, 450, 600]}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Input
                                value={refreshInterval}
                                size="small"
                                onChange={(e) => setRefreshInterval(e.target.value ?? 0)}
                                inputProps={{
                                    step: 5,
                                    min: 1,
                                    max: 600,
                                    type: 'number',
                                    'aria-labelledby': 'slider-with-input',
                                }}
                            />
                        </Grid>
                    </Grid>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <IconButton onClick={() => handleSubmitDialog(photoInterval, refreshInterval)}>
                    <Done color="green" />
                </IconButton>
                <IconButton onClick={handleCloseDialog}>
                    <Cancel color="red" />
                </IconButton>
            </DialogActions>
        </Dialog>
    );
}

export default SlideshowDialog;