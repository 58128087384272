import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUploadedImageUrlsSilently, init } from "../../Store/actions/photoActions";
import FullscreenImage from "./FullscreenImage";
import { StoreConstants } from "../../Store/storeConstants";

const PhotoSlideshow = ({ photoInterval, refreshInterval, onClose, allowedVideoTypes }) => {
    const dispatch = useDispatch();

    const { images, offset } = useSelector((state) => state.photoReducer);
    const [selectedImage, setSelectedImage] = useState(0);
    const [thread, setThread] = useState(null);

    useEffect(() => {
        // Set an interval for photo slideshow
        const intervalId = setInterval(() => {
            if (images.length > 0) {
                const newIndex = (selectedImage + offset + 1) % images.length;
                setSelectedImage((prevSelectedImage) => (prevSelectedImage + offset + 1) % images.length);
                if (offset !== 0) {
                    dispatch({
                        type: StoreConstants.RESET_PHOTO_OFFSET
                    });
                }
            }
            else {
                close();
            }
        }, photoInterval * 1000);

        // Store the interval id to clear it on component unmount
        setThread(intervalId);

        return () => {
            clearInterval(intervalId);
        };
    }, [photoInterval, images.length, offset]);

    const close = () => {
        clearInterval(thread);
        onClose();
    }

    useEffect(() => {
        // Fetch the initial set of images
        dispatch(init());

        // Refresh the images at specified interval
        const refreshIntervalId = setInterval(() => {
            dispatch(getUploadedImageUrlsSilently());
        }, refreshInterval * 1000);

        return () => {
            clearInterval(refreshIntervalId);
        };
    }, [dispatch, refreshInterval]);

    const getImage = () => {
        if (!(isExtensionAllowed(images[selectedImage]?.key))) {
            setSelectedImage((selectedImage+1) % images.length);
        } 
        return images[selectedImage] ? images[selectedImage] : {
            url: null,
            key: null
        };
    };
    
  const isExtensionAllowed = (filename) => {
    return !(allowedVideoTypes.includes(filename?.split(".").pop()));
  }

    return (
        <Grid container style={{ textAlign: "center" }}>
            {images.length > 0 && (
                <FullscreenImage
                    src={getImage().url}
                    alt={getImage().key}
                    onClose={close}
                />
            )}
        </Grid>
    );
};

export default PhotoSlideshow;
